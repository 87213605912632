import React from 'react';
import { listDevices } from '../api/geotab'

import { connect } from 'react-redux'
import { authenticate } from '../api/geotab'
import actions from '../store/actions/geotabActions'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {username: "", password: ""};

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: target.value
    });
  }

  login(e) {
    e.preventDefault();
    authenticate(this.state.username, this.state.password);
  }

  render() {
      return (
        <div className="drawer__geotab">
            <div className="drawer__title">Geotab</div>
            <input className="drawer__text_input" type="text" placeholder="email" name="username"
                onChange={this.handleInputChange.bind(this)}
            />
            <input className="drawer__text_input" type="password" placeholder="password" name="password"
                onChange={this.handleInputChange.bind(this)} 
            />
            <button className="drawer__orange_btn" onClick={this.login.bind(this)}>LOGIN</button>
        </div>
      );
  }
};

class Devices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    if (this.props.devices === null || this.props.devices.length === 0) {
      listDevices(this.props.apiKey, this.props.sessionId).then(
        (devices) => {
          this.props.setGeotabDevices(devices);
      })
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;

    this.setState({
      [name]: target.value
    });

    this.props.setGeotabDevice(target.value);
  }

  render() {
    var opts = [<option key=""></option>];

    _.each(this.props.devices, (device) => {
      var serial = device.serialNumber;
      opts.push(<option value={serial} key={serial}>{serial}</option>);
    });

    return (
      <div className="drawer__geotab">
        <div className="drawer__title">Geotab Device</div>
        <div className="form-group">
          <select className="custom-select" name="device" onChange={this.handleInputChange}>
            {opts}
          </select>
        </div>
        <button className="drawer__orange_btn logout" onClick={this.props.onLogout}>LOGOUT</button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    devices: state.geotab.devices,
    selectedDevice: state.geotab.deviceSerial,
    sessionId: state.geotab.sessionId,
    apiKey: state.geotab.apiKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGeotabDevice: (device) => dispatch(actions.setGeotabDevice(device)),
    setGeotabDevices: (devices) => dispatch(actions.setGeotabDevices(devices)),
    logoutUser: () => dispatch(actions.logoutUser())
  }
}

const GeotabLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
const GeotabDevices = connect(mapStateToProps, mapDispatchToProps)(Devices);


export {GeotabLogin, GeotabDevices};
