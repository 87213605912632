import actionTypes from "../actionTypes";

const API_KEY_NAME = 'apiKey';
const SESSION_ID_NAME = 'sessionId';

const initialState = {
    sessionId: '',
    apiKey: '', 
    loggedIn: false,
    devices: [],
    deviceSerial: ''
}

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.LOGIN: {
            const { sessionId } = action;
            const { apiKey } = action;

            localStorage.setItem(API_KEY_NAME, apiKey);
            localStorage.setItem(SESSION_ID_NAME, sessionId);

            return {
                ...state,
                sessionId,
                apiKey,
                loggedIn: true
            }
        }
        case actionTypes.LOGOUT: {

            localStorage.removeItem(API_KEY_NAME);
            localStorage.removeItem(SESSION_ID_NAME);

            return {
                ...state,
                loggedIn: false,
                apiKey: '',
                sessionId: '',
                devices: [],
                deviceSerial: ''
            }
        }
        case actionTypes.SET_GEOTAB_DEVICES: {
            const { devices } = action;
            return {
                ...state,
                devices
            }
        }
        case actionTypes.SET_GEOTAB_DEVICE: {
            const { deviceSerial } = action;
            return {
                ...state,
                deviceSerial
            }
        }
        default:
            return state;
    }
}
