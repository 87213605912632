import React from 'react';
import '../css/Navbar.css';

function Navbar(props) {
  const logoutUser = () => {
   localStorage.removeItem("user_jwt")
   window.location.reload();
  }
  return (
    <nav id="navbar">
        <ul className="navbar__nav_links">
            {props.views.map(view =>
              <li onClick={() => {props.setCurrent(view)}} key={view.name} className="navbar__nav_link">{view.name}
                <div className={`navbar__underline ${props.current.name === view.name ? 'active' : ''}`}></div>
              </li>
            )}
            <div className="navbar__logout">
              <li onClick={() =>  logoutUser()} className="navbar__nav_link">Logout
              </li>
            </div>
        </ul>
    </nav>
  );
}

export default Navbar;
