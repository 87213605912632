import actionTypes from '../actionTypes';
const initialState = {
    location: true,
    ignition: false,
    simulated: false
}

export default (state = initialState, action) => {
    switch (action.type) {
            case actionTypes.TOGGLE_LOCATION: {
            const { location, device } = action
            if (device !== null) {
              device.toggleLocationStatus(location);
            }
            return {
                ...state,
                location
            }
        }
        case actionTypes.TOGGLE_IGNITION: {
            const { ignition } = action;
            return {
                ...state,
                ignition
            }
        }
        case actionTypes.TOGGLE_SIMULATED: {
            const simulated = action.simulated;
            return {
                ...state,
                simulated
            }
        }
        default:
            return state;
    }
}
