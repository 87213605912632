export default {
    // geotab
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_GEOTAB_DEVICES: 'SET_GEOTAB_DEVICES',
    SET_GEOTAB_DEVICE: 'SET_GEOTAB_DEVICE',

    // driving
    UPDATE_LAT: 'UPDATE_LAT',
    UPDATE_LONG: 'UPDATE_LONG',
    UPDATE_SPEED: 'UPDATE_SPEED',
    UPDATE_BEARING: 'UPDATE_BEARING',
    UPDATE_FULL_LOCATION: 'UPDATE_FULL_LOCATION',
    TOGGLE_LOCATION: 'TOGGLE_LOCATION',
    TOGGLE_IGNITION: 'TOGGLE_IGNITION',
    TOGGLE_SIMULATED: 'TOGGLE_SIMULATED',

    // mobile device
    SET_BT_STATUS: 'SET_BT_STATUS',
    SET_BT_DEVICE: 'SET_BT_DEVICE',
    SET_USB_STATUS: 'SET_USB_STATUS',
    SET_USB_DEVICE: 'SET_USB_DEVICE',

    // ui
    SET_UI_FOCUS: 'SET_UI_FOCUS',
}
