// utils for working on lat/long


const RADIUS = 6.371e6;
const degToRad = Math.PI / 180;


const calculateDistance = (point1, point2) => {
  const lat1 = point1.lat * Math.PI/180;
  const lat2 = point2.lat * Math.PI/180;

  const deltaLat = (point2.lat - point1.lat) * Math.PI/180;
  const deltaLon = (point2.long - point1.long) * Math.PI/180;

  const a = Math.sin(deltaLat/2) * Math.sin(deltaLat/2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(deltaLon/2) * Math.sin(deltaLon/2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))

  return RADIUS * c;
}

const calculateSpeed = (point1, point2, time) => {
  return calculateDistance(point1, point2) / time;
}

const calculateBearing = (pt1, pt2) => {
  const point1 = {"lat": pt1.lat * degToRad, "long": pt1.long * degToRad};
  const point2 = {"lat": pt2.lat * degToRad, "long": pt2.long * degToRad};
  const y = Math.sin(point2.long - point1.long) * Math.cos(point2.lat);
  const x = Math.cos(point1.lat) * Math.sin(point2.lat) -
            Math.sin(point1.lat) * Math.cos(point2.lat) * Math.cos(point2.long - point1.long)
  const bearing = ( (Math.atan2(y,x) * 180/ Math.PI) + 360) % 360;
  return bearing;
}

const calculateNewPoint = (startingPoint, distance, bearingDirection) => {
  const point = {"lat": startingPoint.lat * degToRad, "long": startingPoint.long * degToRad};
  const bearing = bearingDirection * degToRad;
  const newLat = Math.asin(
    Math.sin(point.lat) * Math.cos(distance / RADIUS) +
    Math.cos(point.lat) * Math.sin(distance / RADIUS) * Math.cos(bearing)
  );
  const newLong = point.long + Math.atan2(
    Math.sin(bearing) * Math.sin(distance / RADIUS) * Math.cos(point.lat),
    Math.cos(distance / RADIUS) - Math.sin(point.lat) * Math.sin(newLat)
  )
  return {
    "lat": newLat * (180/Math.PI),
    "long": newLong * (180 / Math.PI)
  }
}

export {
  calculateDistance,
  calculateSpeed,
  calculateBearing,
  calculateNewPoint
}
