import actionTypes from '../actionTypes'

export default {
    loginUser: (apiKey, sessionId) => ({
        type: actionTypes.LOGIN,
        apiKey,
        sessionId
    }),
    logoutUser: () => ({
        type: actionTypes.LOGOUT,
    }),
    setGeotabDevices: (devices) => ({
        type: actionTypes.SET_GEOTAB_DEVICES,
        devices
    }),
    setGeotabDevice: (deviceSerial) => ({
        type: actionTypes.SET_GEOTAB_DEVICE,
        deviceSerial
    })
}
