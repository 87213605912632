import React from 'react';
import _ from 'lodash';
import MainLoop from 'mainloop.js';

import icons from './icons';


import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM'
import {transform} from 'ol/proj'
import {Style, Icon, Stroke, Fill} from 'ol/style';
import { Circle, Point } from 'ol/geom'
import Feature from 'ol/Feature';
import { Vector as SourceVector } from 'ol/source';
import { Vector as LayerVector } from 'ol/layer';

const MAX_ZOOM      = 28;
const STARTING_ZOOM = 20;
const PI            = 3.141592;

// Earth Circumference in m (used by OpenLayers)
const EARTH_CIRCUMFERENCE = 6371008.8;

const walkPoint = (lat, longitude, distance, heading) => {
  //convert to radians
  const bearing = heading * (PI / 180);
  lat *= PI / 180;
  longitude *= PI / 180;

  const newLat = Math.asin(
    (Math.sin(lat) * Math.cos(distance / EARTH_CIRCUMFERENCE)) + (Math.cos(lat) * Math.sin(distance / EARTH_CIRCUMFERENCE) * Math.cos(bearing))
  );
  const newLong = longitude +
    Math.atan2(
      Math.sin(bearing) * Math.sin(distance / EARTH_CIRCUMFERENCE) * Math.cos(lat), Math.cos(distance / EARTH_CIRCUMFERENCE) - (Math.sin(lat) * Math.sin(newLat))
    );
  // convert back to degrees
  return {
    latitude: newLat * (180 / PI),
    longitude: newLong * (180 / PI)
  }
}

// need to transform coordinates to map projection
const transformCoords = (latitude, longitude) => transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')


class MapDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastPosition: null,
      up: false,
      right: false,
      down: false,
      left: false,
      speed: 0, // km/h
      heading: 0, // degrees
      lat: this.props.lat, //40.670992,
      lng: this.props.long, //-73.984849,
      view: new View({
        center: [this.props.lat, this.props.long],
        zoom: STARTING_ZOOM,
        rotation: 0,
        constrainRotation: false,
        projection: 'EPSG:3857'
      }),
      iconFeature: new Feature({
        geometry: new Point(transformCoords(this.props.lat, this.props.long))

      })
    };
    this.keydown = this.keydown.bind(this);
    this.keyup = this.keyup.bind(this);
  }

  componentDidMount() {

    // represent our icon as a single point
    const iconFeature = new Feature({
      geometry: new Point(transformCoords(this.props.lat, this.props.long))
    })
    // style the point with the image of our icon
    const iconStyle = new Style({
      image: new Icon({
        src: icons[Math.floor(Math.random() * icons.length)]
      })
    })
    this.state.iconFeature.setStyle(iconStyle)

    // creating layer overtop of map types to display our icon 
    const sVector = new SourceVector({
      features: [this.state.iconFeature],
      projection: 'EPSG:3857'
    })
    const markerLayer = new LayerVector({
      source: sVector
    })


    this.$map = new Map({
      target: this.mapEl,
      controls: null,
      layers: [
        new TileLayer({source: new OSM()}),
        markerLayer
      ],
      view: this.state.view
    })
    this.state.view.setMaxZoom(100);

    document.addEventListener('keydown', this.keydown);
    document.addEventListener('keyup', this.keyup);
    MainLoop.setMaxAllowedFPS(60);
    MainLoop.setUpdate(this.update.bind(this))
            .setDraw(this.draw.bind(this))
            .start();
  }

  componentWillUnmount() {
    while (this.mapEl.lastChild) {
      this.mapEl.removeChild(this.mapEl.lastChild);
    }
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('keyup', this.keyup);

    MainLoop.stop();
  }

  keydown(e) {
    if (e.keyCode === 38 /* up */ || e.keyCode === 87 /* w */ || e.keyCode === 90 /* z */){
      this.state.up = true
    }
    if (e.keyCode === 39 /* right */ || e.keyCode === 68 /* d */){
      this.state.right = true
    }
    if (e.keyCode === 40 /* down */ || e.keyCode === 83 /* s */){
      this.state.down = true
    }
    if (e.keyCode === 37 /* left */ || e.keyCode === 65 /* a */ || e.keyCode === 81 /* q */){
      this.state.left = true
    }
    if (e.keyCode == 32 /* space */) {
      // stop page from scrolling down
      e.preventDefault();
      this.state.speed = 0.0;
    }
  }

  keyup(e) {
    if (e.keyCode === 38 /* up */ || e.keyCode === 87 /* w */ || e.keyCode === 90 /* z */){
      this.state.up = false
    }
    if (e.keyCode === 39 /* right */ || e.keyCode === 68 /* d */){
      this.state.right = false
    }
    if (e.keyCode === 40 /* down */ || e.keyCode === 83 /* s */){
      this.state.down = false
    }
    if (e.keyCode === 37 /* left */ || e.keyCode === 65 /* a */ || e.keyCode === 81 /* q */){
      this.state.left = false
    }
  }

  update(delta) {
    if (this.state.up && this.props.isIgnitionOn) {
      this.state.speed += 0.2;
    }
    if (this.state.right) {
      this.state.heading = (this.state.heading + 1) % 360;
    }
    if (this.state.down || (this.state.speed > 0 && !this.props.isIgnitionOn)) {
      this.state.speed -= 0.1;
      if (Math.abs(this.state.speed < 0.09)) {
        this.state.speed = 0.0;
      }
    }
    if (this.state.left) {
      this.state.heading = (this.state.heading - 1 + 360) % 360;
    }
    let distance = (delta / 60.0 / 60.0) * this.state.speed;
    let newPosition = walkPoint(this.props.lat, this.props.long, distance, this.state.heading)

    const {latitude, longitude} = newPosition;

    this.props.updateLat(latitude);
    this.props.updateLong(longitude);
    this.props.updateLocation(latitude, longitude, this.state.speed.toFixed(2), this.state.heading);
  }

  draw() {
    const transformedCoords = transformCoords(this.props.lat, this.props.long);

    this.$map.getView().setCenter(transformedCoords);
    this.state.iconFeature.getGeometry().setCoordinates(transformedCoords)
    this.state.view.setZoom(STARTING_ZOOM - (this.state.speed / 200))
    this.state.view.setRotation(2*PI - (this.state.heading * PI / 180));
  }

  render() {
    return <div className="col my-4 mr-3 py-2" id="map" ref={mapEl => this.mapEl = mapEl}></div>;
  }
}

// https://developers.google.com/youtube/iframe_api_reference
class SoundEffects extends React.Component {
  render() {
    return (
      <iframe id="ytplayer" type="text/html" width="266" height="200" src="https://www.youtube.com/embed/2LtiHla1dNg?start=252&enablejsapi=1&origin=http://localhost:8080" frameBorder="0"></iframe>
    );
  }
}

export default MapDisplay;
