//External imports
import { useEffect, useRef } from 'react'
import jwt from 'jwt-decode'

const loadGsiScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
})

const GoogleLogin = () => {
  const handleCredentialResponse = (response) => {
    localStorage.setItem("user_jwt",response.credential);
    window.location.reload(false);
  }

  const googleButton = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = process.env.REACT_APP_GSUITE_CLIENT_ID

    loadGsiScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        })
        google.accounts.id.renderButton(
          googleButton.current, 
          { theme: 'outline', size: 'large', width: 400} 
        )
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  return (
    <div ref={googleButton}></div>
  )
}

export default GoogleLogin 
