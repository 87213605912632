import React, { useState } from "react";
import uuid from 'react-uuid';
import '../index.css';

export const ActionRequestForm = ({apiKey}) => {
  let [type, setType] = useState("PUT")
  let [username, setUsername] = useState("");
  let [title, setTitle] = useState(null);
  let [reuseHandle, setReuseHandle] = useState(false);
  let [inputHandle, setInputHandle] = useState("");
  let [body, setBody] = useState(null);
  let [action1, setAction1] = useState("");
  let [action2, setAction2] = useState("");
  let [sound, setSound] = useState("default");
  let [snoozeEnabled, setSnoozeEnabled] = useState(false);
  let [responseList, setResponseList] = useState([]);
  let [errors, setErrors] = useState(null);

  const createRequestBody = () => {
    const actions = createActionsObject();
    const reqBody = {}
    const snoozeObj = {}
    snoozeObj.enabled = snoozeEnabled

    reqBody.title = title
    reqBody.body = body
    reqBody.actions = actions
    reqBody.sound = sound == "none" ? null : sound
    reqBody.snooze = snoozeObj

    return reqBody
  }

  const createResponseListItem = (message, handle) => {
    var date = new Date();
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} -`;

    const newResponse = 
        <div>
            <div>{`${time}${message}`}</div>
            <div style={{marginLeft:"20px"}}> {`Handle: ${handle}`}</div>
        </div>;
    setResponseList(responseList.concat(newResponse));
  }

  const parseActionInput = (input, fieldId) => {
    if (input != "") {
        try {
            const escaped = input.replace(/(\r\n|\n|\r)/gm, "")
            return JSON.parse(escaped)
        } catch {
            const newError = `Error parsing action ${fieldId}, invalid JSON`
            setErrors(newError)
            return null;
        }
    }
  }

  const createActionsObject = () => {
    let actionsObject = []
    setErrors(null)

    if (action1 != "") { actionsObject.push(parseActionInput(action1, "1")) }
    if (action2 != "") { actionsObject.push(parseActionInput(action2, "2")) }
    
    return actionsObject;
  }

  const sendActionRequest = () => {
    const body = createRequestBody()
    console.log(body)
    if (body.actions.some(action => action == null)) {return}

    const baseUrl = "https://squid-fortress-staging.eleostech.com/api";
    const requestOptions = {
      method : type,
      headers : {
        "Authorization" : `Key key=${apiKey}`,
        "Content-Type" : "application/json"
      }
    }
    if (type == "PUT") { requestOptions.body = JSON.stringify(body) }
    
    let handle;
    if (reuseHandle && inputHandle) {
        handle = inputHandle
    } else {
        handle = uuid()
        setInputHandle(handle)
    }
    
    const requestUrl = `${baseUrl}/v1/action_requests/${username}/${handle}`
        
    fetch(requestUrl, requestOptions)
      .then((resp) => {
        return resp.text()
        .then((response) => {
          const newResponse = ` Response Status: ${resp.status}, Response Body: ${response}`;
          createResponseListItem(newResponse, handle);
        })
      }) 
      .catch((error) => {
        const errorMessage = ` Response: Error - ${error.message}`
        createResponseListItem(errorMessage, handle);
      });
  };

  const handleActionRequest = (event) => {
    event.preventDefault()
    if (apiKey == "") {
      alert("Please enter an API key")
      return;
    }
    sendActionRequest();
  }

  const ResponseList = () => {
    return (
      responseList.map(resp => {
        const key = uuid()
        return <li key={key} style={{marginBottom:"5px"}}>{resp}</li>
      })
    )
  }

  return (
    <div style={{display: "flex", flexDirection: "row"}}>
    <div>
    <form style={{display:"flex", flexDirection:"column"}} onSubmit={((e) => handleActionRequest(e))}>
        <label className="formTextInput">
          Type:
          <select value = {type} name = "type" onChange = {(e) => setType(e.target.value)}>
            <option value = "PUT">Create</option>
            <option value = "DELETE">Delete</option>
          </select>
        </label>
        <label className="formTextInput">
          Username:
          <input name = "username" type = "text" value = {username} onChange = {(e) => setUsername(e.target.value)} />
        </label>
        <label className="formTextInput">
          Handle:
          <input name = "handle" type = "text" value = {inputHandle} onChange = {(e) => setInputHandle(e.target.value)} />
        </label>
        <label className="formTextInput">
          Preserve Handle:
          <input name = "reuseHandle" type = "checkbox" checked = {reuseHandle} onChange = {(e) => setReuseHandle(e.target.checked)} />
        </label>
        <label className="formTextInput">
          Title:
          <input disabled={type == "DELETE"} name = "title" type = "text" value = {title || ""} onChange = {(e) => setTitle(e.target.value)} />
        </label>
        <label className="formTextInput">
          Body:
          <input disabled={type == "DELETE"} name = "body" type = "text" value = {body || ""} onChange = {(e) => setBody(e.target.value)} />
        </label>
        <label className="formTextInput">
          Sound:
          <select disabled={type == "DELETE"} value = {sound} name = "sound" onChange = {(e) => setSound(e.target.value)}>
            <option value = "none">None</option>
            <option value = "default">Default</option>
            <option value = "honk">Honk</option>
            <option value = "guitar">Guitar</option>
            <option value = "echo">Echo</option>
            <option value = "marimba">Marimba</option>
          </select>
        </label>
        <label className="formTextInput">
          Snooze Enabled:
          <input disabled={type == "DELETE"} name = "snoozeEnabled" type = "checkbox" checked = {snoozeEnabled} onChange = {(e) => setSnoozeEnabled(e.target.checked)} />
        </label>
        {errors && <div style={{color:"red", marginTop:"10px"}}>{errors}</div>}
        <label>
          <p>Action 1:</p>
          <textarea disabled={type == "DELETE"} cols={50} rows={3} name = "action1" type = "text" value ={action1} onChange = {(e) => setAction1(e.target.value)} className={"actionInput"}/>
        </label>
        <label>
          <p>Action 2:</p>
          <textarea disabled={type == "DELETE"} cols={50} rows={3} name = "action2" type = "text" value ={action2} onChange = {(e) => setAction2(e.target.value)} className={"actionInput"}/>
        </label>
        <button type="submit" className = "button" style={{paddingLeft:"20px",paddingRight:"20px",marginTop:"16px",width:"80%",alignSelf:"center"}}>SEND</button>
      </form>
      </div>
      <div style={{marginLeft: "416px"}}>
      <ul style={{listStyleType: "none", marginTop: "0px", paddingLeft:"0px"}}>
        <ResponseList/>
      </ul>
    </div>
    </div>
  )
}