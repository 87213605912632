import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { verifyToken } from './components/AuthHandler';
import Mapplet from './views/Mapplet'
import { TestScripts } from './views/TestScripts'
import Navbar from './components/Navbar'
import { Provider } from 'react-redux'
import 'regenerator-runtime/runtime';
import store from './store';
import './css/Navbar.css'
import GoogleLogin from './components/GoogleLogin'
import jwt from 'jwt-decode'

const MainView = () => {
  const [user, updateUser] = useState(null);
  const [views,updateView] = useState([
      {
        name: 'Testscripts',
        view: <TestScripts />
      },
      {
        name: 'Geotab RC',
        view: <Mapplet />
      }
    ]);
  const [currentView, updateCurrentView] = useState(views[0]);

  const handleViewUpdate = (tempView) => {
      updateCurrentView(tempView);
  }

   useEffect(() => {
    var user, user_jwt;
    user_jwt = localStorage.getItem("user_jwt")
    if(user_jwt != null && user_jwt != "null"){
      try {
        user = jwt(user_jwt)
        updateUser(jwt(user_jwt))
      }catch(e) {
        console.log("failed to decode Google's jwt: ", e)
        user_jwt = localStorage.removeItem("user_jwt")
      }
    }
    else {
      updateUser(null)
    }
    return () => {
      updateUser(null)
    };
  }, []);

  if(user != null && (user.exp > (Math.floor(Date.now() / 1000)))){
    return (
      <Provider store={store}>
        <div id="MainView">
          <Navbar views={views} current={currentView} setCurrent={handleViewUpdate}/>
          {currentView.view}
        </div>
      </Provider>
    );
  }
  else {
    return (
      <div id="LoginCard">
        <GoogleLogin />
      </div>
    );
  }
}


ReactDOM.render(
  <MainView />,
  document.getElementById('root')
)
module.hot.accept();
