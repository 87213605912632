import actionTypes from '../actionTypes';

const initialState = {
    focusedElement: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_UI_FOCUS: {
            const  { focusedElement } = action;
            return {
                ...state,
                focusedElement
            }
        }
        default:
            return state;
    }
}
