import { combineReducers } from 'redux'

import driving from './driving'
import mobileDevice from './mobileDevice'
import geotab from './geotab'
import ui from './ui'

export default combineReducers({
    driving,
    mobileDevice,
    geotab,
    ui
})
