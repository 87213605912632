import React from 'react'
import { connect } from 'react-redux';
import drivingActions from '../store/actions/drivingActions'
import mobileDeviceActions from '../store/actions/mobileDeviceActions'
import driving from '../store/reducers/driving';

class DriveController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: this.props.lat,
            long: this.props.long,
        }
    }
    
    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        switch(name) {
            case 'lat': this.setState({lat: parseFloat(value)}); break;
            case 'long': this.setState({long: parseFloat(value)}); break;
            default: break;
        }
    }

    handleCheckboxChange(e) {
        const name = e.target.name;
        const value = e.target.checked;
        if (name === 'location') {
            this.props.toggleLocation(value, this.props.bluetoothDevice);
        }
        else if (name === 'ignition') {
            this.props.toggleIgnition(value);
        }
    }

    updateLocation() {
        this.props.updateLocation(this.state.lat, this.state.long, this.state.simulatedSpeed);
    }

    render() {
        return (
            <div className="drawer__drive">
            <div className="drawer__title">Drive</div>
            <div className="drawer__location_container">
                <div className="drawer__sub_container">
                    <input className="drawer__text_input" type="text" placeholder="Latitude" name='lat' onChange={this.handleInputChange.bind(this)}/>
                    <input className="drawer__text_input" type="text" placeholder="Longitude" name='long' onChange={this.handleInputChange.bind(this)}/>
                    <button className="drawer__orange_btn" onClick={this.updateLocation.bind(this)}>UPDATE</button>
                </div>
                
                <div className="drawer__sub_container">
                    <div className="drawer__toggle_container">
                        <div className="drawer__toggle">
                            <div className="drawer__toggle_item">
                                <label htmlFor="ignition">Ignition</label>
                                <input id="ignition" type="checkbox" name="ignition" onChange={this.handleCheckboxChange.bind(this)} defaultChecked={this.props.ignition}/>
                            </div>
                            <div className="drawer__toggle_item">
                                <label htmlFor="location">Location</label>
                                <input id="location" type="checkbox" name="location" onChange={this.handleCheckboxChange.bind(this)} defaultChecked={this.props.location}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        )
    }
}

const MapStateToProps = (state) => {
    return {
        location: state.driving.location,
        ignition: state.driving.ignition,
    }
}

const MapDispatchToProps = (dispatch) => {
    return {
        toggleLocation: (location, device) => dispatch(drivingActions.toggleLocation(location, device)),
        toggleIgnition: (ignition) => dispatch(drivingActions.toggleIgnition(ignition)),
    }
}

export default connect(MapStateToProps, MapDispatchToProps)(DriveController);
