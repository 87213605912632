import actionTypes from '../actionTypes'

export default {
    updateLat: (lat) => ({
        type: actionTypes.UPDATE_LAT,
        lat,
    }),
    updateLong: (long) => ({
        type: actionTypes.UPDATE_LONG,
        long,
    }),
    updateSpeed: (speed) => ({
        type: actionTypes.UPDATE_SPEED,
        speed,
    }),
    updateBearing: (bearing) => ({
        type: actionTypes.UPDATE_BEARING,
        bearing,
    }),
    toggleLocation: (location, device) => ({
        type: actionTypes.TOGGLE_LOCATION,
        location,
        device,
    }),
    updateFullLocation: (lat, long, speed, bearing) =>({
        type: actionTypes.UPDATE_FULL_LOCATION,
        lat,
        long,
        speed,
        bearing
    }),
    toggleIgnition: (ignition) => ({
        type: actionTypes.TOGGLE_IGNITION,
        ignition,
    }),
    toggleSimulated: (simulated) => ({
        type: actionTypes.TOGGLE_SIMULATED,
        simulated,
    })
}
