
// this needs to be before the svg declaration for OpenLayers
const SVG_DATA_STRING = 'data:image/svg+xml;utf8,'

const bicycle = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="14.4"><g transform="translate(0,-1037.9622)"><path d="m 19.219108,1042.7622 c -0.614,0 -1.2012,0.1109 -1.7414,0.3186 l -2.913,-4.6932 a 0.89999947,0.89999944 0 0 0 -0.7647,-0.4254 l -2.4,0 c -0.3314,0 -0.6,0.2686 -0.6,0.6 l 0,0.6 c 0,0.3314 0.2686,0.6 0.6,0.6 l 1.8993,0 0.5586,0.9 -4.2579,0 0,-0.6 c 0,-0.3314 -0.2686,-0.6 -0.6,-0.6 l -3.2797,0 c -0.504,0 -0.9291,0.4125 -0.9201,0.9164 0.01,0.4891 0.4079,0.8836 0.8998,0.8836 l 1.8273,0 -1.1032,1.782 c -0.5037,-0.1811 -1.0464,-0.2806 -1.6122,-0.282 -2.6334,-0.01 -4.79650006,2.138 -4.81180005296,4.7713 -0.016000007,2.6642 2.13940005296,4.8287 4.79990005296,4.8287 2.2366,0 4.116,-1.5297 4.6488,-3.6 l 1.9512,0 a 0.89999947,0.89999944 0 0 0 0.7653,-0.4263 l 3.2235,-5.2073 0.5602,0.9025 c -0.9545,0.8793 -1.5519,2.1405 -1.549,3.5414 0.01,2.5812 2.1712,4.7644 4.7523,4.7894 2.6478,0.026 4.8068,-2.0926 4.8471,-4.7248 0.041,-2.6603 -2.1197,-4.8645 -4.7803,-4.8749 z m -12.216,2.7664 c 0.3648,0.3949 0.6252,0.8873 0.7366,1.4336 l -1.624,0 0.8874,-1.4336 z m -2.2031,5.0336 c -1.6542,0 -3,-1.3458 -3,-3 0,-1.6542 1.3458,-3 3,-3 0.2201,0 0.4345,0.024 0.6413,0.07 l -1.7065,2.7566 c -0.3705,0.5985 0.061,1.3737 0.7652,1.3737 l 3.0488,0 c -0.4639,1.0584 -1.5211,1.8 -2.7488,1.8 z m 6.0987,-3.6 -1.3359,0 c -0.1485,-1.1909 -0.7334,-2.246 -1.5894,-3.001 l 0.928,-1.499 4.783,0 -2.7857,4.5 z m 8.1396,3.5957 c -1.5388,-0.081 -2.7768,-1.3384 -2.836,-2.8783 -0.03,-0.792 0.2487,-1.5194 0.7251,-2.0724 l 1.6662,2.6845 c 0.1747,0.2816 0.5446,0.3681 0.8262,0.1934 l 0.5098,-0.3164 c 0.2815,-0.1748 0.3681,-0.5447 0.1933,-0.8262 l -1.6675,-2.6866 a 2.9962858,2.9962856 0 0 1 0.7446,-0.094 c 1.6542,0 3,1.3458 3,3 0,1.7078 -1.4344,3.0869 -3.1617,2.9957 z"/></g></svg>';

var bus = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g transform="translate(0,-1028.3622)"> <path d="m 22.875001,1034.3622 -0.375,0 0,-2.25 c 0,-2.1 -4.65,-3.75 -10.5,-3.75 -5.8500004,0 -10.5000004,1.65 -10.5000004,3.75 l 0,2.25 -0.375,0 c -0.62109002,0 -1.12500002711632,0.5034 -1.12500002711632,1.125 l 0,3.75 c 0,0.6211 0.50391000711632,1.125 1.12500002711632,1.125 l 0.375,0 0,7.5 c 0,0.8283 0.67172,1.5 1.5,1.5 l 0,1.5 c 0,0.8283 0.67172,1.5 1.5,1.5 l 1.5,0 c 0.82828,0 1.5,-0.6717 1.5,-1.5 l 0,-1.5 9.0000004,0 0,1.5 c 0,0.8283 0.67172,1.5 1.5,1.5 l 1.5,0 c 0.82828,0 1.5,-0.6717 1.5,-1.5 l 0,-1.5 0.3,0 c 0.75,0 1.2,-0.6 1.2,-1.2 l 0,-7.8 0.375,0 c 0.6211,0 1.125,-0.5039 1.125,-1.125 l 0,-3.75 c 0,-0.6216 -0.5039,-1.125 -1.125,-1.125 z m -15.3750004,-2.625 c 0,-0.2072 0.16781,-0.375 0.375,-0.375 l 8.2500004,0 c 0.20719,0 0.375,0.1678 0.375,0.375 l 0,0.75 c 0,0.2072 -0.16781,0.375 -0.375,0.375 l -8.2500004,0 c -0.20719,0 -0.375,-0.1678 -0.375,-0.375 l 0,-0.75 z m -2.25,15.375 c -0.82828,0 -1.5,-0.6717 -1.5,-1.5 0,-0.8283 0.67172,-1.5 1.5,-1.5 0.82828,0 1.5,0.6717 1.5,1.5 0,0.8283 -0.67172,1.5 -1.5,1.5 z m 6.0000004,-5.25 -5.2500004,0 c -0.82828,0 -1.5,-0.6717 -1.5,-1.5 l 0,-4.5 c 0,-0.8283 0.67172,-1.5 1.5,-1.5 l 5.2500004,0 0,7.5 z m 1.5,0 0,-7.5 5.25,0 c 0.82828,0 1.5,0.6717 1.5,1.5 l 0,4.5 c 0,0.8283 -0.67172,1.5 -1.5,1.5 l -5.25,0 z m 6,5.25 c -0.82828,0 -1.5,-0.6717 -1.5,-1.5 0,-0.8283 0.67172,-1.5 1.5,-1.5 0.82828,0 1.5,0.6717 1.5,1.5 0,0.8283 -0.67172,1.5 -1.5,1.5 z"/></g></svg>';

const car = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16.8">  <g transform="translate(0,-1035.5622)"> <path d="m 20.400001,1041.5622 -0.6,0 -4.07925,-5.0992 a 2.4009376,2.4009375 0 0 0 -1.87425,-0.9008 l -8.0216203,0 c -0.98138,0 -1.86375,0.5974 -2.22825,1.5086 l -1.79663,4.5762 C 0.76650072,1041.9147 7.1525585e-7,1042.8451 7.1525585e-7,1043.9622 l 0,4.2 c 0,0.3315 0.26850000474415,0.6 0.60000000474415,0.6 l 1.79999998,0 c 0,1.9883 1.61175,3.6 3.6,3.6 1.98825,0 3.6,-1.6117 3.6,-3.6 l 4.8000003,0 c 0,1.9883 1.61175,3.6 3.6,3.6 1.98825,0 3.6,-1.6117 3.6,-3.6 l 1.8,0 c 0.3315,0 0.6,-0.2685 0.6,-0.6 l 0,-3 c 0,-1.9882 -1.61175,-3.6 -3.6,-3.6 z m -14.4000003,9 c -0.99262,0 -1.8,-0.8074 -1.8,-1.8 0,-0.9926 0.80738,-1.8 1.8,-1.8 0.99263,0 1.8,0.8074 1.8,1.8 0,0.9926 -0.80737,1.8 -1.8,1.8 z m 2.7,-9 -4.31512,0 1.44,-3.6 2.87512,0 0,3.6 z m 1.8000003,0 0,-3.6 3.3465,0 2.88,3.6 -6.2265,0 z m 7.5,9 c -0.99262,0 -1.8,-0.8074 -1.8,-1.8 0,-0.9926 0.80738,-1.8 1.8,-1.8 0.99263,0 1.8,0.8074 1.8,1.8 0,0.9926 -0.80737,1.8 -1.8,1.8 z"/></g></svg>';

const cart = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.333"><g transform="translate(0,-1031.0292)"> <path d="m 22.005009,1043.584 1.96971,-8.6666 c 0.1422,-0.6258 -0.33342,-1.2217 -0.97513,-1.2217 l -16.3659198,0 -0.38192,-1.8671 c -0.0952,-0.4653 -0.50466,-0.7995 -0.97971,-0.7995 l -4.27203999,0 c -0.55229,0 -0.99999999942196,0.4477 -0.99999999942196,1 l 0,0.6666 c 0,0.5523 0.44770999942196,1 0.99999999942196,1 l 2.91178999,0 2.927,14.3098 c -0.70025,0.4027 -1.17212,1.1578 -1.17212,2.0236 0,1.2886 1.04467,2.3333 2.33333,2.3333 1.28867,0 2.3333398,-1.0447 2.3333398,-2.3333 0,-0.6531 -0.26863,-1.2432 -0.7009998,-1.6667 l 8.7352898,0 c -0.43233,0.4235 -0.70096,1.0136 -0.70096,1.6667 0,1.2886 1.04467,2.3333 2.33334,2.3333 1.28866,0 2.33333,-1.0447 2.33333,-2.3333 0,-0.9239 -0.537,-1.7222 -1.31579,-2.1002 l 0.22987,-1.0115 c 0.14221,-0.6258 -0.33341,-1.2217 -0.97512,-1.2217 l -11.1840898,0 -0.27271,-1.3333 12.2143798,0 c 0.46692,0 0.87167,-0.3231 0.97513,-0.7784 z"/></g></svg>';

const plane = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.333"><g transform="translate(0,-1031.0292)"> <path d="m 19.999983,1039.029 -4.762106,0 -4.379187,-7.6642 a 0.66725312,0.66725308 0 0 0 -0.57917,-0.3358 l -2.7291785,0 c -0.442919,0 -0.762504,0.4237 -0.640836,0.8496 l 2.042926,7.1504 -4.285853,0 -1.800009,-2.4 c -0.125834,-0.1679 -0.323751,-0.2667 -0.533336,-0.2667 l -1.66625702,0 c -0.433752,0 -0.752087,0.4075 -0.64667,0.8284 l 1.31292302,4.505 -1.31292302,4.505 c -0.105417,0.4208 0.212918,0.8283 0.64667,0.8283 l 1.66625702,0 c 0.210001,0 0.407502,-0.099 0.533336,-0.2666 l 1.800009,-2.4 4.285853,0 -2.042926,7.15 c -0.121668,0.4258 0.197917,0.85 0.640836,0.85 l 2.7291785,0 c 0.239168,0 0.460003,-0.1283 0.578753,-0.3358 l 4.379604,-7.6642 4.762106,0 c 1.472923,0 4.000018,-1.1938 4.000018,-2.6667 0,-1.4729 -2.527095,-2.6667 -4.000018,-2.6667 z"/></g></svg>';

const pram = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g transform="translate(0,-1028.3622)"> <path d="m 17.212356,1029.1597 c 0.52967,-0.8343 1.7437,-1.0687 2.53117,-0.4406 2.60149,2.0765 4.25613,5.1749 4.25613,8.6435 l -11.99965,0 5.21235,-8.2029 z m -16.46203042,3.7031 2.24994002,0 c 1.65464,0 2.99991,1.3452 2.99991,2.9999 l 0,2.9999 17.9994804,0 c 0,2.3718 -1.07809,4.5186 -2.82648,6.1264 1.62182,0.4171 2.82648,1.875 2.82648,3.6233 0,2.0718 -1.67807,3.7499 -3.74989,3.7499 -2.07182,0 -3.74989,-1.6781 -3.74989,-3.7499 0,-0.4172 0.0844,-0.8063 0.20624,-1.1813 -1.01247,0.2766 -2.09057,0.4313 -3.20616,0.4313 -1.11559,0 -2.19837,-0.1547 -3.20615,-0.4313 0.12655,0.375 0.20624,0.7641 0.20624,1.1813 0,2.0718 -1.6780804,3.7499 -3.7498904,3.7499 -2.07182,0 -3.74989,-1.6781 -3.74989,-3.7499 0,-1.7483 1.20465,-3.2062 2.82647,-3.6233 -1.74838,-1.6078 -2.82647,-3.7546 -2.82647,-6.1264 l 0,-2.9999 -2.24994002,0 c -0.41249,0 -0.74998000711,-0.3375 -0.74998000711,-0.75 l 0,-1.5 c 0,-0.4124 0.33749000711,-0.7499 0.74998000711,-0.7499 z m 19.49944042,17.2495 c 0.82497,0 1.49995,-0.675 1.49995,-1.5 0,-0.825 -0.67498,-1.4999 -1.49995,-1.4999 -0.82498,0 -1.49996,0.6749 -1.49996,1.4999 0,0.825 0.67498,1.5 1.49996,1.5 z m -14.9995704,-1.5 c 0,0.825 0.67498,1.5 1.49996,1.5 0.82497,0 1.49995,-0.675 1.49995,-1.5 0,-0.825 -0.67498,-1.4999 -1.49995,-1.4999 -0.82498,0 -1.49996,0.6749 -1.49996,1.4999 z"/></g></svg>';

const rocket = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.425"><g id="rocket" transform="translate(0,-1032.9372)"> <path id="path4336" d="m 23.886848,1043.0328 c 0.150887,-0.2326 0.150867,-0.5343 -3.3e-5,-0.767 -0.795273,-1.229 -1.587393,-2.0211 -2.376369,-2.8101 -3.243906,-3.2439 -6.924746,-3.4545 -10.66527,-2.6184 l -3.2753451,-3.2753 c -0.5720814,-0.5721 -1.4176425,-0.7701 -2.1814435,-0.5156 l -4.65844397,1.5529 c -0.7543589,0.2514 -0.9775167,1.2039 -0.4148642,1.7665 l 2.98929687,2.9893 c -0.5878204,0.2169 -1.1756274,0.4275 -1.750827,0.6192 -0.2923845,0.097 -0.4872629,0.3678 -0.4872495,0.6759 l 6.8e-6,3.9982 c -2.02e-5,0.3082 0.1948984,0.5785 0.4872226,0.6759 0.575213,0.1917 1.1599084,0.4055 1.7476753,0.6224 l -2.98929687,2.9893 c -0.5594873,0.5594 -0.3332178,1.5151 0.414891,1.7665 l 4.65531887,1.5497 c 0.7669194,0.2578 1.612494,0.053 2.1814376,-0.5156 l 3.2816141,-3.2815 c 3.737399,0.8267 7.4308,0.616 10.659033,-2.6122 0.795474,-0.7887 1.587595,-1.5809 2.382646,-2.8101 z m -7.732586,1.1253 c -0.83298,-0.8329 -0.833027,-2.1846 0,-3.0176 0.832961,-0.833 2.184617,-0.833 3.017597,0 0.832981,0.833 0.832954,2.1847 -7e-6,3.0176 -0.833028,0.833 -2.184608,0.833 -3.01759,0 z"/> </g></svg>';

const truck = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2"><g transform="translate(0,-1033.1622)"> <path d="m 23.400001,1046.3622 -0.6,0 0,-4.0537 c 0,-0.4763 -0.19125,-0.9338 -0.52875,-1.2713 l -3.74625,-3.7462 c -0.3375,-0.3375 -0.795,-0.5288 -1.27125,-0.5288 l -1.65375,0 0,-1.8 c 0,-0.9937 -0.80625,-1.8 -1.8,-1.8 l -11.9999998,0 c -0.99375001,0 -1.8000000079069,0.8063 -1.8000000079069,1.8 l 0,12 c 0,0.9938 0.8062499979069,1.8 1.8000000079069,1.8 l 0.6,0 c 0,1.9875 1.6125,3.6 3.6,3.6 1.9875,0 3.6,-1.6125 3.6,-3.6 l 4.7999998,0 c 0,1.9875 1.6125,3.6 3.6,3.6 1.9875,0 3.6,-1.6125 3.6,-3.6 l 1.8,0 c 0.33,0 0.6,-0.27 0.6,-0.6 l 0,-1.2 c 0,-0.33 -0.27,-0.6 -0.6,-0.6 z m -17.3999998,4.2 c -0.99375,0 -1.8,-0.8062 -1.8,-1.8 0,-0.9937 0.80625,-1.8 1.8,-1.8 0.99375,0 1.8,0.8063 1.8,1.8 0,0.9938 -0.80625,1.8 -1.8,1.8 z m 11.9999998,0 c -0.99375,0 -1.8,-0.8062 -1.8,-1.8 0,-0.9937 0.80625,-1.8 1.8,-1.8 0.99375,0 1.8,0.8063 1.8,1.8 0,0.9938 -0.80625,1.8 -1.8,1.8 z m 3,-7.8 -5.4,0 0,-4.2 1.65375,0 3.74625,3.7463 0,0.4537 z"/></g></svg>';

const truck_container = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16.805"><g transform="translate(0,-1035.5572)"> <path d="m 23.298751,1043.2558 -2.19375,-2.1938 c -0.45,-0.45 -1.06125,-0.7012 -1.69875,-0.7012 l -2.00625,0 c -0.66375,0 -1.2,0.5362 -1.2,1.2 l 0,5.4 -15.0000002,0 c -0.66374999,0 -1.19999998869757,0.5362 -1.19999998869757,1.2 l 0,1.0425 c 0,1.53 1.07624998869757,2.9287 2.59124998869757,3.1312 1.15125,0.1538 2.18625,-0.3562 2.80875,-1.1887 0.69,0.9262 1.89,1.4512 3.19875,1.1137 0.945,-0.2437 1.7287502,-0.9825 2.0400002,-1.905 0.18375,-0.555 0.2025,-1.095 0.105,-1.59 l 6.12,0 c -0.10125,0.495 -0.0825,1.035 0.105,1.59 0.315,0.9413 1.12125,1.6838 2.085,1.9163 1.98,0.48 3.75,-1.0088 3.75,-2.91 0,-0.2063 -0.0225,-0.405 -0.06,-0.6 l 0.65625,0 c 0.33,0 0.6,-0.27 0.6,-0.6 l 0,-3.2063 c 0,-0.6412 -0.25125,-1.2487 -0.70125,-1.6987 z m -20.2987502,7.3012 c -0.66,0 -1.2,-0.54 -1.2,-1.2 0,-0.66 0.54,-1.2 1.2,-1.2 0.66,0 1.2,0.54 1.2,1.2 0,0.66 -0.54,1.2 -1.2,1.2 z m 4.8,0 c -0.66,0 -1.2,-0.54 -1.2,-1.2 0,-0.66 0.54,-1.2 1.2,-1.2 0.66,0 1.2,0.54 1.2,1.2 0,0.66 -0.54,1.2 -1.2,1.2 z m 12.0000002,0 c -0.66,0 -1.2,-0.54 -1.2,-1.2 0,-0.66 0.54,-1.2 1.2,-1.2 0.66,0 1.2,0.54 1.2,1.2 0,0.66 -0.54,1.2 -1.2,1.2 z m -1.8,-6.6 0,-1.8 1.40625,0 c 0.1575,0 0.31125,0.064 0.42375,0.1763 l 1.62375,1.6237 -3.45375,0 z m -16.8000002,1.8 12.6000002,0 c 0.66375,0 1.2,-0.5362 1.2,-1.2 l 0,-7.8 c 0,-0.6637 -0.53625,-1.2 -1.2,-1.2 l -12.6000002,0 c -0.66374999,0 -1.19999998869767,0.5363 -1.19999998869767,1.2 l 0,7.8 c 0,0.6638 0.53624999869767,1.2 1.19999998869767,1.2 z m 10.2000002,-8.4 1.2,0 0,6.6 -1.2,0 0,-6.6 z m -3.0000002,0 1.2,0 0,6.6 -1.2,0 0,-6.6 z m -3,0 1.2,0 0,6.6 -1.2,0 0,-6.6 z m -3,0 1.2,0 0,6.6 -1.2,0 0,-6.6 z"/></g></svg>';

const truck_fast = ('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2"><g transform="translate(0,-1033.1622)"> <path d="m 23.400001,1046.3622 -0.6,0 0,-4.0537 c 0,-0.4763 -0.19125,-0.9338 -0.52875,-1.2713 l -3.74625,-3.7462 c -0.3375,-0.3375 -0.795,-0.5288 -1.27125,-0.5288 l -1.65375,0 0,-1.8 c 0,-0.9937 -0.80625,-1.8 -1.8,-1.8 l -9.5999998,0 c -0.99375,0 -1.8,0.8063 -1.8,1.8 l 0,1.8 -2.10000001,0 c -0.165,0 -0.299999997907,0.135 -0.299999997907,0.3 l 0,0.6 c 0,0.165 0.134999997907,0.3 0.299999997907,0.3 l 10.19999981,0 c 0.165,0 0.3,0.135 0.3,0.3 l 0,0.6 c 0,0.165 -0.135,0.3 -0.3,0.3 l -8.9999998,0 c -0.165,0 -0.3,0.135 -0.3,0.3 l 0,0.6 c 0,0.165 0.135,0.3 0.3,0.3 l 7.8,0 c 0.165,0 0.3,0.135 0.3,0.3 l 0,0.6 c 0,0.165 -0.135,0.3 -0.3,0.3 l -9.00000001,0 c -0.165,0 -0.299999997907,0.135 -0.299999997907,0.3 l 0,0.6 c 0,0.165 0.134999997907,0.3 0.299999997907,0.3 l 7.80000001,0 c 0.165,0 0.3,0.135 0.3,0.3 l 0,0.6 c 0,0.165 -0.135,0.3 -0.3,0.3 l -5.7,0 0,4.8 c 0,1.9875 1.6125,3.6 3.6,3.6 1.9875,0 3.6,-1.6125 3.6,-3.6 l 4.7999998,0 c 0,1.9875 1.6125,3.6 3.6,3.6 1.9875,0 3.6,-1.6125 3.6,-3.6 l 1.8,0 c 0.33,0 0.6,-0.27 0.6,-0.6 l 0,-1.2 c 0,-0.33 -0.27,-0.6 -0.6,-0.6 z m -17.3999998,4.2 c -0.99375,0 -1.8,-0.8062 -1.8,-1.8 0,-0.9937 0.80625,-1.8 1.8,-1.8 0.99375,0 1.8,0.8063 1.8,1.8 0,0.9938 -0.80625,1.8 -1.8,1.8 z m 11.9999998,0 c -0.99375,0 -1.8,-0.8062 -1.8,-1.8 0,-0.9937 0.80625,-1.8 1.8,-1.8 0.99375,0 1.8,0.8063 1.8,1.8 0,0.9938 -0.80625,1.8 -1.8,1.8 z m 3,-7.8 -5.4,0 0,-4.2 1.65375,0 3.74625,3.7463 0,0.4537 z"/></g></svg>');

const truck_pickup = ('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16.8"><g transform="translate(0,-1035.5622)"> <path d="m 23.400056,1045.1622 -0.6,0 0,-2.4 c 0,-0.6626 -0.53737,-1.2 -1.2,-1.2 l -1.8,0 -4.07925,-5.0993 a 2.4009376,2.4009375 0 0 0 -1.87425,-0.9007 l -4.2465004,0 c -0.66262,0 -1.2,0.5374 -1.2,1.2 l 0,4.8 -6,0 c -0.66263,0 -1.2,0.5374 -1.2,1.2 l 0,2.4 -0.60000002,0 c -0.3315,0 -0.600000007116,0.2685 -0.600000007116,0.6 l 0,1.2 c 0,0.3315 0.268500007116,0.6 0.600000007116,0.6 l 1.86037002,0 c -0.0285,0.1976 -0.0604,0.3945 -0.0604,0.6 0,2.3197 1.88025,4.2 4.2,4.2 2.31975,0 4.2000004,-1.8803 4.2000004,-4.2 0,-0.2055 -0.0319,-0.4024 -0.0604,-0.6 l 2.52112,0 c -0.0285,0.1976 -0.0604,0.3945 -0.0604,0.6 0,2.3197 1.88025,4.2 4.2,4.2 2.31975,0 4.2,-1.8803 4.2,-4.2 0,-0.2055 -0.0319,-0.4024 -0.0604,-0.6 l 1.86,0 c 0.3315,0 0.6,-0.2685 0.6,-0.6 l 0,-1.2 c 0,-0.3315 -0.2685,-0.6 -0.6,-0.6 z m -12.6,-7.2 3.0465,0 2.88,3.6 -5.9265,0 0,-3.6 z m -4.2000004,12 c -0.99262,0 -1.8,-0.8074 -1.8,-1.8 0,-0.9926 0.80738,-1.8 1.8,-1.8 0.99263,0 1.8,0.8074 1.8,1.8 0,0.9926 -0.80737,1.8 -1.8,1.8 z m 10.8000004,0 c -0.99262,0 -1.8,-0.8074 -1.8,-1.8 0,-0.9926 0.80738,-1.8 1.8,-1.8 0.99263,0 1.8,0.8074 1.8,1.8 0,0.9926 -0.80737,1.8 -1.8,1.8 z"/></g></svg>');

const van = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16.8"><g transform="translate(0,-1035.5622)"> <path d="m 23.583001,1042.2616 -5.04337,-6.0518 a 1.8003751,1.8003751 0 0 0 -1.38263,-0.6476 l -15.9570003,0 c -0.66261991,0 -1.19999991186052,0.5374 -1.19999991186052,1.2 l 0,10.8 c 0,0.6626 0.53738000186052,1.2 1.19999991186052,1.2 l 1.2,0 c 0,1.9883 1.61175,3.6 3.6,3.6 1.98825,0 3.6,-1.6117 3.6,-3.6 l 4.8000003,0 c 0,1.9883 1.61175,3.6 3.6,3.6 1.98825,0 3.6,-1.6117 3.6,-3.6 l 1.2,0 c 0.66263,0 1.2,-0.5374 1.2,-1.2 l 0,-4.1482 c 0,-0.4212 -0.14775,-0.8288 -0.417,-1.1524 z m -21.1830003,-0.6994 0,-3.6 3.6,0 0,3.6 -3.6,0 z m 3.6,9 c -0.99412,0 -1.8,-0.8059 -1.8,-1.8 0,-0.9941 0.80588,-1.8 1.8,-1.8 0.99413,0 1.8,0.8059 1.8,1.8 0,0.9941 -0.80587,1.8 -1.8,1.8 z m 6.0000003,-9 -3.6000003,0 0,-3.6 3.6000003,0 0,3.6 z m 6,9 c -0.99412,0 -1.8,-0.8059 -1.8,-1.8 0,-0.9941 0.80588,-1.8 1.8,-1.8 0.99413,0 1.8,0.8059 1.8,1.8 0,0.9941 -0.80587,1.8 -1.8,1.8 z m -3.6,-9 0,-3.6 2.47575,0 3,3.6 -5.47575,0 z"/></g></svg>';

const icons = [bicycle, bus, car, cart, plane, pram, rocket, truck, truck_container, truck_fast, truck_pickup, van];

export default icons.map(imgStr => `${SVG_DATA_STRING}${imgStr}`);
