import React from 'react';
import uuid from 'react-uuid';
import '../TestScripts.css';
import CustomMessageForm from '../CustomMessageForm.js';
import TestScriptButtons from '../TestScriptButtons.js';
import { UserRefreshForm } from '../UserRefreshForm.js';
import { ActionRequestForm } from '../components/ActionRequestForm.js';

const encodeNestedObjects = (params, prefix) => {
	
  var items = [];
  
  for(var field in params) {
    var key = prefix + "[" + field + "]";
    var type = typeof params[field];
    
    switch(type) {
    
      case "object":
        if (params[field] == null) {
          items.push(encodeURIComponent(key) + "=");
        }
        else {
          items = items.concat(encodeNestedObjects(params[field], key));
        }
        break;
      default:
        items.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[field]));
        break;
    }
  }

  return items.filter(x => x !== "").join("&");
}

const encodeFormData = (data) => {
  var url = Object.keys(data)
    .map(key => {
      if (key == "form_data" && data[key] !== "") {
        return encodeNestedObjects(JSON.parse(data[key]), key);
      }
      else {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      }
    })
    .join('&')
    
    return url;
}

class TestScripts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey : "",
      responseMessages : []
    }
    if (localStorage.getItem('apiKey') != null) {
      this.state['apiKey'] = localStorage.getItem('apiKey');
    }
    // bind methods
    this.makeFetchRequest = this.makeFetchRequest.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.makeMessageRequest = this.makeMessageRequest.bind(this);
    // tts message texts
    this.TtsText = {
      normalTTS: "Your trailer, 1 6 5 0 0 3, is reporting a reefer alarm. Code 2 0 0 0 0 1. LOW FUEL LEVEL WARNING. Fuel level is 27.0 gallons.",
      longTTS: "Are you listening to me? ".repeat(14) + "You reached the end of message. Congratulations!!!",
      pokemonTTS: "I want to be the very best, Like no one ever was. To catch them is my real test, To train them is my cause. I will travel across the land, Searching far and wide. Each Pokemon to understand The power thats inside. Pokemon. Gotta catch em all. Its you and me, I know its my destiny.",
      commonAbrevTTS: "The time is 11:00 pm the 17th of march. Order #149. It takes 14 hr to cook chicken. I weigh 14 lb and my cat weighs 90 kg",
      spanishTTS: "Tienes una nueva orden asignada: 724890"
    };
  }

  makeFetchRequest(options, endPoint) {
      var newRespMessage;
      const handle = uuid();
      const url = "https://squid-fortress-staging.eleostech.com/api/" + endPoint;
      var date = new Date();
      var time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}-`;
      // add message sent to the response list
      this.setState({ responseMessages: this.state.responseMessages.concat([`${time}Request Sent at ${url}`]) });

      fetch(url, options).then((response) => {
        if (response.OK) {
          localStorage.setItem('apiKey', this.state.apiKey);
        }
        time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}-`;
        // add the response message to the response list
        newRespMessage = `${time}Response: ${response.status}(${response.statusText})`;
        this.setState({ responseMessages: this.state.responseMessages.concat([newRespMessage]) });
      }).catch((e) => {
        console.log(e)
        newRespMessage = `${time}Error sending request: ${e}`
        this.setState({ responseMessages: this.state.responseMessages.concat([newRespMessage]) });
      });
  }

  makeMessageRequest(data) {
    var numRequests = 1;
    if (data["alert"] == false) {
      delete data.sound;
    }
    if (data["numRequests"] != null) {
      var numRequests = data["numRequests"];
      delete data.numRequests;
    }
    if (data["message_type"] == null) {
      data["message_type"] = "text";
    }
    if (data["direction"] == null) {
      data["direction"] = "outbound";
    }
    if (data["body"] == null) {
      data["body"] = "test";
    }
    if (this.state.apiKey == null || this.state.apiKey.length == 0) {
      alert("Please enter a API key");
      return;
    }
    if (data["form_code"] == "") {
      alert("Form Code cannot be empty")
      return;
    }
    else {
      if (data["message_type"] == "form") {
        
        delete data.body;

        if (data["form_data"] != "") {
          try {
            JSON.parse(data["form_data"]);
          }
          catch {
            alert("Incorrect JSON for Form Data");
            return;
          }
        }
        else {
          alert("Form Data cannot be empty");
        }
      }

      console.log(data);
      const options = {
        method : 'PUT',
        headers : {
          "Authorization" : `Key key=${this.state.apiKey}`,
          "Content-Type" : "application/x-www-form-url-encoded;charset=UTF-8"
        },
        body: encodeFormData(data)
      };
      for (var i = 0; i < numRequests; i++) {
        const handle = uuid();
        this.makeFetchRequest(options, "v1/messages/" + handle);
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name] : value
    });
  }
  render() {
    const { responseMessages } = this.state;
    return (
      <div id="testscripts">
        <label>
          API Key:
            <input type = "text" name = "apiKey" value = {this.state.apiKey} onChange = {this.handleInputChange} />
        </label>
        <p> API key is required to run the preset message scripts below and any custom message</p>
        <div className = "responseBox">
          <h3>Responses</h3>
          <ul>
            {responseMessages.map(function (name, index) {
              return <li key = {index}>{name}</li>;
            })}
          </ul>
        </div>
        <TestScriptButtons MessageFunction = {this.makeMessageRequest} TtsText = {this.TtsText} />
        <h3> Custom Message </h3>
        <CustomMessageForm MessageFunction = {this.makeMessageRequest} />
        <div style={{display: "flex", flexDirection: "row"}}>
          <h3 style={{marginTop:"0px"}}> User Refresh Request </h3>
          <h3 style={{marginTop:"0px", marginLeft: "645px"}}> Responses </h3>
        </div>
        <UserRefreshForm apiKey={this.state.apiKey}/>
        <div style={{display: "flex", flexDirection: "row"}}>
          <h3 style={{marginTop:"50px"}}> Action Request </h3>
        </div>
        <ActionRequestForm apiKey={this.state.apiKey}/>
      </div>
    )
  }
}
export { TestScripts }
