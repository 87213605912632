import React from 'react';

class CustomMessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numRequests : 1,
      message_type : "text",
      direction : "outbound",
      body : "test",
      username : "",
      alert : true,
      sound : "none",
      text_to_speech_announcement : ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'alert' ? target.checked : target.value;
    const name = target.name;

    if (value === "text") { 
      delete this.state.form_code;
      delete this.state.form_data;
      this.state.body = "test";
    }
    else if (value === "form") {
      delete this.state.body;
      this.state.form_code = "1.39-START-STOP-O";
      this.state.form_data = '{"LOAD-NUMBER": "123","STOP-NUMBER": "2"}';
    }

    this.setState({
      [name] : value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.username.length == 0) {
      alert("Username field required for custom message");
    }
    else if (!this.state.body && this.state.body == "") {
      alert("Body field required for custom message");
    }
    else if (this.state.numRequests > 200) {
      alert("Number of Messages must be less than 200 for custom message");
    }
    else {
      this.props.MessageFunction(this.state);
    }
  }

  render() {
    
    const handleMessageTypeSwitch = () => {
      if (this.state.message_type === 'text') {
        return (
          <>
            <label>
              Body:
              <input name = "body" type = "text" value = {this.state.body} onChange = {this.handleInputChange} />
            </label>
            <br />
          </>
      )}
      else {
        return (
          <>
            <label>
              Form Code:
              <input name = "form_code" type = "text" value = {this.state.form_code} onChange = {this.handleInputChange} />
            </label>
            <br />
            <label>
              <p>Form Data:</p>
              <textarea style={{marginLeft: 5}} class="form_data_textarea" cols={50} rows={4} name = "form_data" type = "text" value = {this.state.form_data} onChange = {this.handleInputChange} />
            </label>
            <br />
          </>
      )}
    }

    return (
      <form onSubmit = {this.handleSubmit}>
        <label>
          Direction:
          <select value = {this.state.direction} name = "direction" onChange = {this.handleInputChange}>
            <option value = "outbound">Outbound</option>
            <option value = "inbound">Inbound</option>
          </select>
        </label>
        <br />
        <label>
          Message Type:
          <select value = {this.state.message_type} name = "message_type" onChange = {this.handleInputChange}>
            <option value = "text">Text</option>
            <option value = "form">Form</option>
          </select>
        </label>
        <br />

        {handleMessageTypeSwitch()}
        
        <label>
          Number of Messages:
          <input name = "numRequests" value = {this.state.numRequests} onChange = {event => this.setState({ numRequests: event.target.value.replace(/\D/, '') })} />
        </label>
        <br />
        <label>
          Username:
          <input name = "username" type = "text" value = {this.state.username} onChange = {this.handleInputChange} />
        </label>
        <br />
        <label>
          Alert:
          <input name = "alert" type = "checkbox" checked = {this.state.alert} onChange = {this.handleInputChange} />
        </label>
        <br />
        <label>
          Sound:
          <select value = {this.state.sound} name = "sound" onChange = {this.handleInputChange}>
            <option value = "none">None</option>
            <option value = "default">Default</option>
            <option value = "honk">Honk</option>
            <option value = "guitar">Guitar</option>
            <option value = "echo">Echo</option>
            <option value = "marimba">Marimba</option>
          </select>
        </label>
        <br />
        <label>
          text_to_speech_announcement:
          <input name = "text_to_speech_announcement" type = "text" value = {this.state.text_to_speech_announcement} onChange = {this.handleInputChange} />
        </label>
        <br />
        <input type = "submit" value = "Send Custom Message" />
      </form>
    );
  }
}

export default CustomMessageForm;
