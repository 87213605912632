import React, { useState } from "react";

export const UserRefreshForm = ({apiKey}) => {
  let [username, setUsername] = useState("");
  let [responseList, setResponseList] = useState([]);

  const sendRefreshRequest = () => {
    const baseUrl = "https://squid-fortress-staging.eleostech.com/api";
    const requestOptions = {
      method : 'POST',
      headers : {
        "Authorization" : `Key key=${apiKey}`
      }
    }

    const requestUrl = `${baseUrl}/v1/users/${username}/updates`
    var date = new Date();
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}-`;
        
    fetch(requestUrl, requestOptions)
      .then((response) => {
        const newResponse = `${time}Response: ${response.status}(${response.statusText})`;
        setResponseList(responseList.concat(newResponse));
      })
      .catch((error) => {
        const newResponse = `${time}Response: Error - ${error.message}`;
        setResponseList(responseList.concat(newResponse));
      });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handleRefreshRequest = () => {
    sendRefreshRequest();
  }

  const ResponseList = () => {
    return (
      responseList.map(resp => {
        return <li>{resp}</li>
      })
    )
  }

  return (
    <div>
      <div style={{display: "flex", flexDirection: "row"}}>
        <div>
          Username:
          <input name = "username" type = "text" value={username} onChange={handleUsernameChange} style={{marginRight: "15px"}}></input>
          <button className = "button" style={{paddingLeft:"20px",paddingRight:"20px",marginLeft:"10px"}} onClick={(handleRefreshRequest)}>SEND</button>
        </div>
        <div style={{marginLeft: "480px"}}>
          <ul style={{listStyleType: "none", marginTop: "0px", paddingLeft:"0px"}}>
            <ResponseList/>
          </ul>
        </div>
      </div>
    </div>
  )
}