import React from 'react';
import MapDisplay from '../map'
import '../index.css';
import { connect } from 'react-redux';
import uiActions from '../store/actions/uiActions'
// Geotab functions
import {
  updateGeotabLocation,
} from '../api/geotab'

import MappletDrawer from '../components/MappletDrawer';
import {
  calculateSpeed
} from '../location';
import '../css/MapContainer.css'
import drivingActions from '../store/actions/drivingActions';

const SPEED = 200;
const RADIUS = 6.371e6;

const exponentialMovingAverage = (newVal, oldVal) => {
  const alpha = 0.35;
  newVal * alpha + oldVal * (1 - alpha);
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
  }

function getCardinalDirection(direction) {
  if (direction > 337.5 || direction < 22.5) {
    return 'N';
  }
  else if (direction > 22.5 && direction < 67.5) {
    return 'NE';
  }
  else if (direction > 67.5 && direction < 112.5) {
    return 'E';
  }
  else if (direction > 112.5 && direction < 157.5) {
    return 'SE';
  }
  else if (direction > 157.5 && direction < 202.5) {
    return 'S';
  }
  else if (direction > 202.5 && direction < 247.5) {
    return 'SW';
  }
  else if (direction > 247.5 && direction < 292.5) {
    return 'W';
  }
  else if (direction > 292.5 && direction < 337.5) {
    return 'NW';
  }
  else {
    return 'invalid bearing'
  }
}


class Mapplet extends React.Component {
    constructor(props) {
        super(props);

        let lat  = getUrlVars()["lat"];
        let long = getUrlVars()["long"];
        this.state = {
                       speed: 0,
                       direction: 0,
                       selectedFile: null,
                       lat: lat == null ? 40.67 : parseFloat(lat),
                       long: long == null ? -73.98 : parseFloat(long),
                       simulated: false,
                       simulatedSpeed: null,
                       pointList: [],
                       currentPoint: 0,
                       currentTime: Date.now() / 1000.0,
                       updateRate: 100,
                       percentCompleted: 0,
                     };
        this.updateLocation = _.throttle(this.updateGpsLocation.bind(this), 100);
        this.updateGeotab = _.throttle(updateGeotabLocation, 2000);
      }

      toggleSimulation(value) {
        this.setState({simulated: value});
      }
    
      render() {
        return (
          <div id="Mapplet">
            <MappletDrawer name="drawer"
              lat={this.state.lat}
              long={this.state.long}
              direction={this.state.direction}
              simulatedSpeed={this.state.simulatedSpeed}
              updateLat={this.updateLat.bind(this)}
              updateLong={this.updateLong.bind(this)}
            />

          <div id="map__container">
                <MapDisplay
                    name="map"
                    lat={this.state.lat}
                    long={this.state.long}
                    isIgnitionOn={this.props.ignition}
                    updateLat={this.updateLat.bind(this)}
                    updateLong={this.updateLong.bind(this)}
                    updateLocation={this.updateLocation}
               />
                <div className="map__overlay">
                    <div className="map__overlay_item">
                        <label htmlFor="overlay_latitude">latitude</label>
                        <div id="overlay_latitude">{this.state.lat.toFixed(8)}</div>
                    </div>
                    <div className="map__overlay_item">
                        <label htmlFor="overlay_longitude">longitude</label>
                        <div id="overlay_longitude">{this.state.long.toFixed(8)}</div>
                    </div>
                    <div className="map__overlay_item">
                        <label htmlFor="overlay_speed">Speed</label>
                        <div id="overlay_speed">{this.state.speed} (m/s)</div>
                    </div>
                    <div className="map__overlay_item">
                        <label htmlFor="overlay_direction">Direction</label>
                        <div id="overlay_direction">{getCardinalDirection(this.state.direction)}  ({this.state.direction}°)</div>
                    </div>
                </div>
            </div>
          </div>
        );
      }
      updatePointList(pointList) {
        this.setState({pointList});
      }
      updateGpsLocation(latitude, longitude, speed, direction) {
        if (this.props.simulated === true && this.state.pointList !== null) {
          if (this.state.currentPoint >= this.state.pointList.length) {
            this.props.toggleSimulated(false);
            this.setState({currentPoint: 0, pointList: null});
          }
          else {
            const newTime = Date.now() / 1000.0;
            const point = this.state.pointList[this.state.currentPoint];
            const newSpeed =  calculateSpeed({'lat': this.state.lat, 'long': this.state.long}, point, newTime -  this.state.currentTime).toFixed(2);
            if (newSpeed > 1000) console.log(`new point is ${point.lat}, ${point.long} old point is ${this.state.lat}, ${this.state.long}`);

            latitude = point['lat'];
            longitude = point['long'];
            speed = newSpeed;
            if (point['bearing'] != null) {
              direction = point['bearing'];
            }

            this.setState({
              lat: latitude,
              long: longitude,
              currentPoint: this.state.currentPoint+1,
              speed: speed,
              currentTime: newTime,
              direction: direction,
              percentCompleted: ((this.state.currentPoint/this.state.pointList.length)*100).toFixed(2),
            });
          }
        }
        else {
          this.setState({lat: latitude, long: longitude, speed: speed, direction: direction});
        }
        this.updateGeotab(latitude, longitude, speed);
      }
    
      updateLat(value) {
        this.setState({lat: value});
      }
      updateSimulatedSpeed(value) {
        this.setState({simulatedSpeed: value});
      }
      updateLong(value) {
        this.setState({long: value});
      }
      updateDirection(value) {
        this.setState({direction: value});
      }
}

const mapStateToProps = (state) => {
  return {
    ignition: state.driving.ignition,
    simulated: state.driving.simulated,
    isLoggedIn: state.geotab.loggedIn,
    sessionId: state.geotab.sessionId,
    geotabDevices: state.geotab.devices,
    geotabDevice: state.geotab.device,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFocusedElement: (element) => dispatch(uiActions.setFocusedElement(element)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Mapplet);

