import React from 'react';
import '../css/MappletDrawer.css';
import { GeotabLogin, GeotabDevices } from './MyAdmin';
import { connect } from 'react-redux'
import DriveController from './DriveController'
import geotabActions from '../store/actions/geotabActions'
import uiActions from '../store/actions/uiActions'


class MappletDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            devices: null
        }
    }

    toggleVisible() {
        this.setState({isVisible: !this.state.isVisible})
    }

    updateLocation(lat, long, simulatedSpeed) {
        this.props.updateLat(lat);
        this.props.updateLong(long);
        this.props.updateSimulatedSpeed(simulatedSpeed);
    }

    componentDidMount() {
        let sessionId = localStorage.getItem('sessionId');
        let apiKey = localStorage.getItem('apiKey');
        if (sessionId && apiKey) {
            if (!this.props.loggedIn) {
                this.props.loginUser(apiKey, sessionId);
            }
        }

    }

    render() {
        return (
        <div id="drawer">
        <div className="drawer__contents">
            {
                this.props.loggedIn ? 
                    <GeotabDevices onLogout={this.props.logoutUser} /> : 
                    <GeotabLogin onLogin={this.props.loginUser} />
            }
            <DriveController 
                lat={this.props.lat} 
                long={this.props.long} 
                simulatedSpeed={this.props.simulatedSpeed}
                updateLocation={this.updateLocation.bind(this)}
            />
        </div>
    </div>
    )};
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.geotab.loggedIn,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (apiKey, sessionId) => dispatch(geotabActions.loginUser(apiKey, sessionId)),
        logoutUser: () => dispatch(geotabActions.logoutUser()),
        selectDevice: (device) => dispatch(geotabActions.setGeotabDevice(device)),
        setGeotabDevices: (devices) => dispatch(geotabActions.setGeotabDevices(devices)),
        setFocus: (element) => dispatch(uiActions.setFocusedElement(element))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MappletDrawer);
