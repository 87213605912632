import store from '../store'
import actions from '../store/actions/geotabActions'
import { concat } from 'lodash'

const SERVER_URL = "https://myadminapi.geotab.com/v2/MyAdminApi.ashx";



const handleError = (error, errorCallback) => {
    var errorString;
    if (error && error.name && error.message) {
      errorString = error.name + ": " + error.message;
      if (error.message.startsWith('SessionExpiredException')) {
        store.dispatch(actions.logoutUser());
      }
    }
    else if (error.target || (error instanceof XMLHttpRequest && error.status === 0)) {
      errorString = "Network Error: Couldn't connect to the server. Please check your network connection and try again.";
    }
    if (errorCallback) {
      errorCallback(errorString || error, error);
    }
}

const geotabCall = (method, params, callbackSuccess, callbackError) => {
    let apiMethod = {
        "id": -1,
        method,
        params
    }

    var request = new XMLHttpRequest();
    request.open('POST', SERVER_URL, true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        request.onreadystatechange = () => {
          if (request.readyState === 4) {
            if (request.status === 200) {
              var data,
                  error,
                  result;
              try {
                data = JSON.parse(request.responseText);
                console.log("geotabCall request:", request, "response: ", data);
                if (data && data.error) {
                  error = data.error;
                  handleError(error, callbackError);
                }
                else {
                  result = data.result;
                  callbackSuccess(result);
                }
              }
              catch (e) {
                handleError(e, callbackError);
              }
            } else {
              handleError(request, callbackError);
            }
          }
        };
        request.send("JSON-RPC=" + encodeURIComponent(JSON.stringify(apiMethod)));
}

const listDevices = (apiKey, sessionId, offset = null) => {
    return new Promise((resolve, reject) => {
      if (sessionId && apiKey) {

        var params = {
          apiKey,
          sessionId,
          forAccount: "eleo01"
        };
        if (offset !== null) {
          params['nextId'] = offset;
        }

        var filterDevices = function(dbs) {
          var devices = _.filter(dbs, (device) => {
            // must start with C or D and must also be in eleos_test or eleos_prerelease
            return ( (device.serialNumber.startsWith("C") || device.serialNumber.startsWith("D")
                      || device.serialNumber.startsWith("H")) &&
                     (device.databaseName == 'eleos_test' || device.databaseName == 'eleos_prerelease') );
          });
          if (dbs.length == 1000) {
            listDevices(apiKey, sessionId, dbs[999].id)
            .then((nextDevices) => {
              resolve(concat(devices, nextDevices));
            });
          }
          else resolve(devices);
        }

        geotabCall('GetCurrentDeviceDatabases',
                        params,
                        filterDevices,
                        reject
        );
      } else {
        resolve(null);
      }
    });
  }

const authenticate = (username, password) => {
    let logonParams = {
        username,
        password,
      };
  
      geotabCall('Authenticate', logonParams, user => {
          store.dispatch(actions.loginUser(user.userId, user.sessionId));
  
      }, (errorMessage, error) => {
        var errorIdx, alertMsg = 'Error Message: ' + errorMessage;
        if (error && error.errors) {
          alertMsg += '\n\nThe following errors occurred:\n';
          for (errorIdx in error.errors) {
            alertMsg += error.errors[errorIdx].name + '\n';
          }
        }
        alert(alertMsg);
      });
}

const updateGeotabLocation = (latitude, longitude, speed) => {
    const state = store.getState();
    if (state.geotab.sessionId && state.geotab.apiKey) { // Geotab check
      if (state.geotab.deviceSerial) {
        console.log("addGps", arguments);
        var params = {
          apiKey: state.geotab.apiKey,
          sessionId: state.geotab.sessionId,
          recordsToAdd: [{
            serialNo: state.geotab.deviceSerial,
            dateTime: (new Date()).toISOString(), //'2018-12-07T16:04:04-04:00',
            longitude: longitude,
            latitude: latitude,
            isGpsValid: true,
            speed: speed,
            isIgnitionOn: state.driving.ignition,
            type: 'ThirdPartyGpsRecord'
          },
          {
            serialNo: state.geotab.deviceSerial,
            dateTime: (new Date()).toISOString(),
            Code: 154,
            Value: Math.floor(speed),
            type: 'ThirdPartyStatusRecord'
          }
          ]
        };
        geotabCall('AddData', params, (device) => {
          console.log("added data: ", device, arguments);
        });
      }
    }
  }

export {
  geotabCall,
  listDevices,
  authenticate,
  updateGeotabLocation
}
