import actionTypes from '../actionTypes'

const initialState = {
    bluetoothDevice: null,
    bluetoothConnected: false,
    usbDevice: null,
    usbConnected: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BT_STATUS: {
            const bluetoothConnected = action.status;
            return {
                ...state,
                bluetoothConnected
            }
        }
        case actionTypes.SET_BT_DEVICE: {
            const { bluetoothDevice } = action;
            if (bluetoothDevice !== null) {
              bluetoothDevice.seqNum = 0;
            }
            return {
                ...state,
                bluetoothDevice
            }
        }
        case actionTypes.SET_USB_STATUS: {
          const usbConnected = action.status;
          return {
            ...state,
            usbConnected
          }
        }
        case actionTypes.SET_USB_DEVICE: {
          const { usbDevice } = action;
          return {
            ...state,
            usbDevice
          }
        }
        default:
            return state;
    }
}
